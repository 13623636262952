<template>
  <mdb-container>
    <div class="text-right">
      <router-link to="/admin/youtubeManageList">
        <mdb-btn outline="info" size="md" class="my-3" icon="arrow-left">
          뒤로가기
        </mdb-btn>
      </router-link>
    </div>
    <mdb-card>
      <h3 class="card-header unique-color white-text text-center font-weight-bold text-uppercase py-2 ">영상 등록</h3>
      <div class="card-body">
        <label for="orderNumber" class="grey-text">순번</label>
        <input type="text" id="orderNumber" class="form-control" v-model="order_number" placeholder="0">
        <br>
        <label for="title" class="grey-text">타이틀</label>
        <input type="text" id="title" class="form-control" v-model="title">
        <br>
        <label for="video_id" class="grey-text">video ID</label>
        <input type="text" id="video_id" class="form-control" v-model="video_id">
        <br>
<!--        <label for="url" class="grey-text">노출 유무</label>-->
<!--        <mdb-form-inline>-->
<!--          <mdb-input type="radio" id="option5-1" name="groupOfMaterialRadios2" radioValue="true" v-model="show"-->
<!--                     label="true" @input="handleRadioChange"/>-->
<!--          <mdb-input type="radio" id="option5-2" name="groupOfMaterialRadios2" radioValue="false" v-model="show"-->
<!--                     label="false" checked @input="handleRadioChange"/>-->
<!--        </mdb-form-inline>-->
        <div class="text-center mt-1">
          <mdb-btn outline="success" @click="onUpload()" icon="pen">등록</mdb-btn>
        </div>
        <!-- Default form contact -->
      </div>
      <!-- Card body -->
    </mdb-card>
  </mdb-container>
</template>

<script>
import {mdbCard, mdbContainer, mdbBtn,} from 'mdbvue';
import firebase from "firebase/app";

export default {
  name: "YoutubeRegistration",
  data() {
    return {
      id: "",
      title: "",
      order_number: '',
      video_id: '',
      show: false,
    }
  },
  components: {
    mdbCard,
    mdbContainer,
    mdbBtn,
  },
  methods: {
    handleRadioChange(value) {
      const self = this;
      self.show = value === "true";
    },
    getSelectValue(value) {
      const self = this;
      self.fields = value;
    },
    onUpload() {
      const self = this;
      const db = firebase.firestore();

      if(self.order_number === ''){
        self.order_number = 0
      }
      const data = {
        order_number: self.order_number,
        show: self.show,
        title: self.title,
        video_id: self.video_id,
        reg_date: firebase.firestore.Timestamp.fromDate(new Date()),
      }

      db.collection('Youtube')
          .add(data)
          .then((querySnapshot) => {
            alert('영상 등록이 완료되었습니다.');
            this.$router.push('/admin/youtubeManageList').catch((err) => {
              console.log(querySnapshot.id)
              console.log(err)
            })
          })
          .catch((err) => {
            // 실패 후 실행할 코드
            console.log(err)
          })
    },
  }
}
</script>

<style scoped>

</style>